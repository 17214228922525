@import './../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.dhsv_mylist_checkout {
  z-index: 11;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  padding: 30px 0;
  background-color: rgba($grey, 0.5);

  @include media-breakpoint-down(lg) {
    top: 70px;
    height: calc(100vh - 70px);
  }

  @include media-breakpoint-down(md) {
    top: 50px;
    height: calc(100vh - 50px);
  }

  @include media-breakpoint-down(xs) {
    padding: 0;

    > .container {
      padding: 0;
    }
  }
}
